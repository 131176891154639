.displayBlock {
  display: block;
}
.displayNone {
  display: none;
}
.dialog {
  position: fixed;
  pointer-events: all;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--primary-background-dialog);
  &Wrapper {
    margin: 0px auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 6px 6px 0px 0px;
    width: 50vw;
    min-height: auto;
    max-height: 80vh;
  }

  &.dialogSmall {
    .dialogWrapper {
      height: auto;
    }
  }

  &.dialogLarge {
    .dialogWrapper {
      width: 80vw;
    }
  }

  &.dialogFullscreen {
    .dialogWrapper {
      width: 85vw;
    }
  }
}

@media (max-width: 1300px) {
  .dialogWrapper {
    width: 92vw;
    max-height: 95vh;
  }
}

@media (min-width: 1300px) and (max-width: 1400px) {
  .dialogWrapper {
    width: 80vw;
  }
}
