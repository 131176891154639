.DayPicker-Month {
  border-collapse: separate;
}
.DayPicker-WeekNumber {
  outline: none;
}
.DayPicker-Day {
  outline: none;
  border: 1px solid transparent;
}
.DayPicker-Day--hoverRange {
  background-color: #efefef !important;
}

.DayPicker-Day--selectedRange {
  background-color: #fff7ba !important;
  border-top-color: #ffeb3b;
  border-bottom-color: #ffeb3b;
  border-left-color: #fff7ba;
  border-right-color: #fff7ba;
}

.DayPicker-Day--selectedRangeStart {
  background-color: #ffeb3b !important;
  border-left: 1px solid #ffeb3b;
}

.DayPicker-Day--selectedRangeEnd {
  background-color: #ffeb3b !important;
  border-right: 1px solid #ffeb3b;
}

.DayPicker-Day--selectedRange:not(.DayPicker-Day--outside).DayPicker-Day--selected,
.DayPicker-Day--hoverRange:not(.DayPicker-Day--outside).DayPicker-Day--selected {
  border-radius: 0 !important;
  color: black !important;
}
.DayPicker-Day--hoverRange:hover {
  border-radius: 0 !important;
}

.DayPickerInput input {
  width: 130px;
  padding: 5px 8px;
  color: #808080;
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  font-size: 100%;
}
