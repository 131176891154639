.ldsRing {
  display: flex;
  min-width: 80px;
  min-height: 80px;
  z-index: 10;
}
.ldsRing :global(.ring-arc) {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  margin: 8px;
  border: 8px solid #424242;
  border-radius: 50%;
  animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.ldsRing div:nth-child(1):global(.ring-arc) {
  animation-delay: -0.45s;
}
.ldsRing div:nth-child(2):global(.ring-arc) {
  animation-delay: -0.3s;
}
.ldsRing div:nth-child(3):global(.ring-arc) {
  animation-delay: -0.15s;
}
@keyframes ldsRing {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.curtain {
  position: absolute;
  background: #33333336;
  z-index: 999999;
}

.spinnerCenteredWrapper {
  position: absolute;
  height: 100%;
  left: 46px;
  width: calc(100% - 46px);
  top: 0;
  z-index: 999999;
  cursor: wait;
  & > :global(.loading-spinner) {
    top: 50%;
    text-align: center;
    margin: auto;
    position: relative;
    > :global(.loading-spinner) {
      left: 50%;
    }
  }

  :global(.mainbar--expanded) & {
    left: 190px;
    width: calc(100% - 190px);
  }

  :global(.mainbar--none) & {
    left: 0;
    width: 100%;
  }
}
.spinnerText {
  text-align: center;
  line-height: 1.5em;
}
