.divider {
  border-color: var(--primary-light-grey);
  &Horizontal {
    border-top-style: solid;
    border-top-width: 1.5px;
  }
  &Vertical {
    width: 0;
    border-right-width: 1.5px;
    border-right-style: solid;
  }
}
