@use "src/styles/constants" as *;

$buttonFont: $fontPrimary;
$buttonSize: $fontSize;
$buttonSizeLittle: 12px;

.general {
  font-family: $buttonFont;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    padding: 5px;
    display: flex;
  }

  &:global(.ui.button) {
    display: flex;
    gap: 5px;
    div {
      padding: 0;
    }
  }
  .buttonIcon {
    padding-right: 0px;
  }
}

.general:disabled {
  cursor: not-allowed;
  opacity: 0.4;
  background-image: linear-gradient(to right, rgb(189 189 189 / 68%) 40%, rgb(189 189 189 / 68%) 40%);
}
.tertiary:disabled {
  cursor: not-allowed;
  opacity: 0.4;
  background-image: none;
}

.radiusMacroComponent {
  border-radius: 20px 0px 0px 20px;
}
.radiusNormal {
  border-radius: 7px;
}

.big,
.medium {
  font-size: $buttonSize;
}

.small {
  div {
    padding: 3px 5px;
  }
}

.big {
  max-width: 320px;
  min-width: 110px;
}

.little {
  font-size: $buttonSizeLittle;
  max-width: 180px;
}

.little {
  min-width: 100px;
}

.normal,
.green,
.gray,
.warning,
.warningReverse,
.positive,
.white,
.orange,
.darkBlue {
  border: none;
}

.normal,
.green,
.warning,
.positive,
.darkBlue {
  color: #fff;
}

.normal {
  background-color: var(--primary-green);
}

.green {
  background-color: var(--primary-emerald-green);
}

.darkBlue {
  background-color: #3f8086;
}

.white,
.gray {
  color: #000;
}

.white {
  background-color: #fff;
}

.gray {
  background-color: var(--primary-light-grey);
}

.warning {
  background-color: var(--accent-red);
}

.warningReverse {
  background-color: transparent;
  color: var(--accent-red);
}

.positive {
  background-color: var(--accent-green);
}

.orange {
  background-color: var(--accent-orange);
  color: var(--primary-white);
}

.icon {
  display: block;
  float: left;
}
