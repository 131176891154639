$borderColor: var(--primary-light-grey);

.tooltip {
  background-color: #fff;
  color: var(--primary-grey);
  border: 2px solid $borderColor;
  border-radius: 5px;
  max-width: 400px;
  pointer-events: none;
  position: absolute;
  word-break: break-word;
  z-index: 999;

  &:before {
    content: "";
    display: block;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    border-right: 2px solid $borderColor;
    border-top: 2px solid $borderColor;
  }

  &.titled {
    border-color: #d9d9d9;
    box-shadow: 1px 2px 5px #888;
    &:before {
      background-color: var(--primary-light-grey);
      border-right-color: #cecece;
      border-top-color: #cecece;
    }
  }

  &Below {
    &:before {
      top: -1px;
      right: 11px;
      height: 10px;
      width: 10px;
      transform: translateY(-50%) rotateZ(-45deg);
    }
    &Right {
      &:before {
        top: -1px;
        left: 11px;
        height: 10px;
        width: 10px;
        transform: translateY(-50%) rotateZ(-45deg);
      }
    }
  }
  &Left {
    &:before {
      top: 14px;
      right: -6px;
      height: 8px;
      width: 8px;
      transform: translateY(-50%) rotateZ(48deg);
    }
  }
  &Right {
    &:before {
      top: 14px;
      left: -6px;
      height: 8px;
      width: 8px;
      transform: translateY(-50%) rotateZ(-130deg);
    }
  }
  &Above {
    &:before {
      bottom: -2px;
      left: 8px;
      height: 10px;
      width: 10px;
      transform: translateY(50%) rotateZ(135deg);
      background-color: white !important;
    }
  }
  &AboveLeft {
    &:before {
      bottom: -2px;
      right: 8px;
      height: 10px;
      width: 10px;
      transform: translateY(50%) rotateZ(135deg);
      background-color: white !important;
    }
  }

  & > .title {
    border-bottom: 1px solid #ccc;
    font-size: small;
    padding: 5px 10px;
    font-weight: 600;
    background-color: var(--primary-light-grey);
  }
  & > .body {
    padding: 5px 10px;

    b,
    strong {
      font-weight: 600;
    }

    em {
      font-style: italic;
    }
  }

  &:global(.mode-help),
  &:global(.mode-green) {
    border-color: var(--primary-emerald-green);
    box-shadow: 1px 2px 5px #888;

    &:before {
      background-color: var(--primary-emerald-green);
      border-right-color: var(--primary-emerald-green);
      border-top-color: var(--primary-emerald-green);
    }

    &.tooltipAbove,
    &.tooltipAboveLeft {
      &:before {
        background-color: white;
      }
    }

    & > .title {
      background-color: var(--primary-emerald-green);
      color: white;
      font-size: 13px;
      .titleText {
        margin-right: 10px;
      }
    }

    & > .body {
      line-height: 1.4em;

      p {
        margin-bottom: 5px;
      }
    }
  }

  @mixin tooltip-mode-colored($color) {
    border-color: var($color);

    & > .title {
      background-color: var($color);
      color: white;
    }

    &:before {
      background-color: var($color);
      border-right-color: var($color);
      border-top-color: var($color);
    }
  }

  &:global(.mode-warning) {
    @include tooltip-mode-colored(--accent-orange);
  }

  &:global(.mode-danger) {
    @include tooltip-mode-colored(--accent-red);
  }
}
