.wrapper {
  box-shadow: 2px 0 10px rgb(0 0 0 / 18%);
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px 0px;
  min-width: 45px;

  .customerLogo {
    margin-top: 40px;
  }

  @media (max-height: 710px) {
    & .optionMenu {
      padding: 6px 5px;
      font-size: 12px;
      border-bottom: 1px solid #ccc;
      :global(.menu-icon) {
        width: 18px;
        height: 18px;
      }
    }

    & .customerLogo {
      margin-top: 20px;
    }
  }
}

.boxImg,
.boxDividerNoCollapse,
.boxNoCollapseMenu,
.boxEndOption {
  padding: 0px 13px;
}
.image {
  height: 100%;
  width: 100%;
}
.boxIcon {
  align-self: center;
}
.boxDividerCollapse {
  padding: 0px 6px;
}
.boxNoCollapseMenu {
  align-items: center;
  display: flex;
  justify-content: space-between;
  @media (max-width: 1200px) {
    display: none;
  }
}
.boxCollapseMenu {
  left: -8px;
  position: relative;

  @media (max-width: 1200px) {
    display: none;
  }
}

.boxOptions {
  display: flex;
  flex: 2;
  flex-direction: column;

  .boxTextOptions {
    display: flex;
    flex: 2;
  }

  &.collapsed {
    .boxTextOptions {
      display: none;
    }
    .caret {
      display: none;
    }
  }
}

.optionMenu {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 5px;
  padding: 10px;

  :global(.menu-icon) {
    width: 24px;
    height: 24px;
  }

  &:hover {
    text-decoration: none;
  }
}

.optionSelect {
  color: var(--primary-emerald-green);
  background-color: var(--primary-light-grey);
  :global(svg.menu-icon),
  :global(svg.menu-icon) path {
    fill: var(--primary-emerald-green);
  }
}

.boxEndOptionNoCollapse {
  align-items: center;
  background-color: var(--primary-light-grey);
  cursor: pointer;
  display: flex;
  gap: 5px;
}
.boxEndOptionCollapse {
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 10px 0px;
}

.optionMenu:hover,
.boxEndOptionNoCollapse:hover,
.boxEndOptionCollapse:hover {
  color: var(--primary-emerald-green);
  background-color: var(--primary-light-grey);
  :global(svg.menu-icon),
  :global(svg.menu-icon) path {
    fill: var(--primary-emerald-green);
  }
}
