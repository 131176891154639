:root {
  --toastify-color-info: var(--accent-sky-blue);
  --toastify-color-success: var(--accent-green);
  --toastify-color-warning: var(--accent-yellow);
  --toastify-color-error: var(--accent-red);
  --toastify-toast-width: 320px;
  --toastify-z-index: 99999;
}

.Toastify__toast {
  font-size: 15px;
}

.Toastify__toast-container {
  width: auto;
  max-width: 460px;
  text-align: center;
}

.Toastify__toast-body {
  word-break: break-word;
}
