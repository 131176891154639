.dialogHeader {
  border-radius: 6px 6px 0px 0px;
  padding: 11px 0px;
  font-weight: 600;
  text-align: center;
  position: relative;
  min-height: 36px;
  .buttonClose {
    color: var(--primary-light-grey);
    float: right;
    font-size: 27px;
    font-weight: bold;
    position: absolute;
    top: 8px;
    left: 100%;
    padding: 1px 3px 7px 15px;
    background-color: #424242;
    transform: rotate(180deg);
    clip-path: circle(70% at 80%);
    height: 35px;
    line-height: 1.2em;
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
}
