.general_container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  flex-direction: column;
}

.container {
  gap: 2rem;
  padding: 1rem;
  text-align: center;
  // max-width: 500px !important;

  h2 {
    margin: 0 !important;
  }

  .container_icon {
    border-radius: 4px;
    border: 1px solid rgb(226, 226, 226);
    width: 80px !important;
    height: 80px !important;

    .icon {
      cursor: pointer;
    }
  }
}
